import React from "react"
import SEO from "../components/seo"

const TestPage = () => (
    
  <div>
      <SEO title="Plaiz - La page test"/>
      <h1 className="p1">Ceci est un tests</h1>
    </div>
)

export default TestPage